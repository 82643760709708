import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import Helmet from 'react-helmet'
import Layout from "../component/layout"
import favicon from '../images/favicon.ico'


const Lead = props => props.pages.map(page=> ParsePage(page.node.content));
const ParsePage = content => {
  if (content)
  {
    const html = parse(content);
    return html;
  } else {
    return '';
  }
  
}
const Allpages = ({data}) => {
  const pages = data.allWpPage.edges
 const title = pages.map(page=>{
    return(
    <h1>{page.node.title}</h1>)
  })
  const Description = pages.map(page=>{
    return(page.node.allPageDescription.pageDescription)
  })
  const isleadpage= pages.map(page=>{
    return(page.node.slug)
  })
  return (
   isleadpage=="sxsw"
    ? 
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Lead pages={pages}/>
    </React.Fragment>
    :<Layout title={title} captions={Description}>
         {
           pages.map(page=>{
            // const title = page.node.title
            const contents= page.node.content
            const featuredImage = {
              img: page.node.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
              alt: page.node.featuredImage?.node?.alt || ``,
            }
            return (
                      <>
                          
                              {featuredImage?.img && (
                                <div className="mb-4">
                                  <GatsbyImage
                                    image={featuredImage.img}
                                    alt={featuredImage.alt}
                                  />
                                </div>
                              )}
                          <div>{ParsePage(contents)}</div>    
                    </>
                    )

           })
         }
    </Layout>
  )
}

export default Allpages

export const pageQuery = graphql`
query($slug: String){
    allWpPage(filter: {slug: {eq: $slug}}) {
      edges {
        node {
          id
          title
          content
          slug
          allPageDescription{
          pageDescription
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 200
                    layout: FIXED
                    placeholder: DOMINANT_COLOR
                    quality: 100
                  )
                }
              }
            }
          }
        }
      }
    }
  }  
`